import request from '@/plugins/axios'

export function getGroupList(params) {
  return request({
    url: '/gather/groups',
    method: 'get',
    params
  })
}

// 加群
export function addGroup(data) {
  return request({
    url: '/gather/groups',
    method: 'post',
    data
  })
}
