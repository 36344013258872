<template>
  <div class="app-container">
    <div class="top_box">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline account-top"
      >
        <el-form-item label="任务状态">
          <el-select v-model="queryForm.state" placeholder="任务状态" clearable>
            <el-option label="完成" value="1"></el-option>
            <el-option label="待执行" value="0"></el-option>
            <el-option label="执行失败" value="-1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属用户" v-if="isManager">
          <el-input
            v-model="queryForm.owner"
            placeholder="所属用户ID"
            clearable
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="搜索">
        <el-input
          v-model="queryForm.search"
          placeholder="模糊检索..."
        ></el-input>
      </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <el-form
        :inline="true"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-form-inline account-top"
      >
        <el-form-item prop="url">
          <el-input
            v-model="ruleForm.url"
            placeholder="请输入群组链接"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="submitForm('ruleForm')">
            添加链接
          </el-button>
        </el-form-item>
      </el-form>

      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
          @sort-change="handleSort"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="url" label="群组链接"></el-table-column>
          <el-table-column prop="state" label="任务状态">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="getStatusTip(scope.row.state, statusMap)"
                placement="top"
              >
                <el-tag
                  :type="getStatusType(scope.row.state, statusMap)"
                  disable-transitions
                >
                  {{ getStatusLabel(scope.row.state, statusMap) }}
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="群组ID">
            <template slot-scope="scope">
              <span
                @click="$router.push(`/group/detail/${scope.row.group.id}`)"
                v-if="scope.row.group !== null && scope.row.group.id !== null"
              >
                {{ scope.row.group.id }}
              </span>
              <span v-else></span>
              <!-- {{ scope.row.group ? scope.row.group.id : null }} -->
            </template>
          </el-table-column>
          <el-table-column label="群组名称">
            <template slot-scope="scope">
              <span
                @click="$router.push(`/group/detail/${scope.row.group.id}`)"
                v-if="scope.row.group !== null && scope.row.group.name !== null"
              >
                {{ scope.row.group.name }}
              </span>
              <span v-else></span>
              <!-- {{ scope.row.group ? scope.row.group.name : null }} -->
            </template>
          </el-table-column>
          <el-table-column label="创建人备注" v-if="isManager">
            <template slot-scope="scope">
              {{ scope.row.owner ? scope.row.owner.note : null }}
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            sortable="custom"
          ></el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getGroupList, addGroup } from '@/api/gather'
import { getManagerStatus } from '@/utils/manager'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'gatherGroup',
  mixins: [cacheMixin],
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      queryForm: {
        page_index: 1,
        page_size: 10,
        state: '',
        owner: '',
        ordering: '-create_time'
        // search: ''
      },
      statusMap: {
        1: {
          type: 'success',
          label: '执行成功'
        },
        0: {
          type: 'primary',
          label: '等待执行'
        },
        '-1': {
          type: 'danger',
          label: '执行失败'
        },
        '-2': {
          type: 'danger',
          label: '链接失效'
        }
      },
      ruleForm: {
        url: ''
      },
      rules: {
        url: [
          { required: true, message: '请输入群组链接', trigger: 'blur' },
          {
            pattern: /^((http|https):\/\/)?(([A-Za-z0-9]+-[A-Za-z0-9]+|[A-Za-z0-9]+)\.)+([A-Za-z]+)[/?:]?.*$/,
            message: '输入以http或https开头的url地址',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      try {
        const { count, items } = await getGroupList(this.queryForm)
        this.count = count
        this.list = items
      } finally {
        this.listLoading = false
      }
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    // 添加链接
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await addGroup(this.ruleForm)
          this.fetchData()
        } else {
          return false
        }
      })
    },
    handleSort({ column, prop, order }) {
      if (order === null) {
        this.queryForm.ordering = null
      } else if (order === 'descending') {
        this.queryForm.ordering = '-' + prop
      } else {
        this.queryForm.ordering = prop
      }
      console.log(column, prop, order)
      this.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.app-container {
  ::v-deep input.el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  button.el-button.el-button--success {
    height: 32px;
    padding: 0 20px;
  }
}
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;

  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 90px);
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
